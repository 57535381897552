@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(50px);
    }
  }
  .card-wrapper {
    /* background: linear-gradient(135deg, #ff6f61, #d83a56); Light red gradient */
    background: #B38153;
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 8px 65px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    border: 2px solid #B38153; /* Coral border for a vibrant contrast */
  }








  
  .card-content {
    padding: 30px;
  }
  
  .header-info {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .header-info h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff; /* White text */
  }
  
  .header-info p {
    font-size: 1rem;
    line-height: 1.6;
    color: #e0e0e0; /* Light gray text */
  }
  
  @media (max-width: 768px) {
    .header-info h1 {
      font-size: 2rem;
    }
  
    .header-info p {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .header-info h1 {
      font-size: 1.5rem;
    }
  
    .header-info p {
      font-size: 0.75rem;
    }
  }
  
  .timeline-wrapper {
    width: 100%;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 50px;
    padding: 20px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent white background */
    border-radius: 8px;
  }
  
  .timeline-item.reverse {
    flex-direction: row-reverse;
  }
  
  .timeline-item.visible {
    animation: fadeIn 1s forwards;
  }
  
  .timeline-item:not(.visible) {
    animation: fadeOut 1s forwards;
  }
  
  @media (max-width: 768px) {
    .timeline-item {
      flex-direction: column;
      text-align: center;
    }
  }
  
  .text-content {
    width: 45%;
    padding: 20px;
    text-align: center;
    color: #ffffff; /* White text */
  }
  
  .text-content h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #ffffff; /* White text */
  }
  
  .text-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #e0e0e0; /* Light gray text */
  }
  
  @media (max-width: 768px) {
    .text-content {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  
  .image-content {
    width: 45%;
    padding: 20px;
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .image-content img {
    width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Darker shadow for more depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .image-content:hover img {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); /* More pronounced shadow on hover */
  }
  
  @media (max-width: 768px) {
    .image-content {
      width: 100%;
    }
  }
  